import * as React from 'react';
import { http } from '../structures/http';
import { IDDL } from '../structures/IDDL';
import { IBoleta, IRtaBoleta } from '../structures/IBoleta';
import { Spinner, Modal } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
//import { RouteComponentProps } from 'react-router-dom';
import { Dropdown, Table } from 'react-bootstrap';
import { AppContext } from '../AppContext';

const logo = require('../img/logo.svg').default;

class Rifa extends React.Component<IConsumptionProps, IConsumptionState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: IConsumptionProps) {

        super(props);

        this.state = {

            boletas: [],
            premios: [
                        { "id": "IPHONE", "title": "IPhone" }, 
                        { "id": "PARLANTES", "title": "Parlantes" }, 
                    ],
            premio: 'IPHONE',
            show: false,
            showPdf:false
        }
    }



    handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {

        const { name, value } = e.target;

        //console.log(e.target);

        this.setState({
            [name]: value,
            showPdf:false
        } as any, () => this.validar());
    }

    validar() {

        console.log('Validar', this.state.premios, this.state.premio);
    }

    openPDF( e: any ) {
        const url: string = this.context.url + "/reporte_ganadores_" + this.state.premio + '.pdf';
        console.log( url );
        window.open( url, '_blank', 'noopener,noreferrer' );
    }

    updateTable(e: any) {

        this.setState({
            show: true

        }, () => this.showTable());
    }

    showTable() {

        this.loadBoletas();
    }

    async loadBoletas() {

        const url: string = this.context.url + "/index.php?entryPoint=obtenerganador&sid=" + this.context.sid + "&premio=" + this.state.premio + "&XDEBUG_SESSION_START=PHPSTORM";
        console.log(url);
        const data = await http<IRtaBoleta>(url);

        //console.log(data);

        if (data.codigo == 100) {

            this.setState({
                boletas: data.rta == null ? [] : data.rta,
                show: false,
                showPdf: true
            });
        }
        else {

            this.setState({
                boletas: [],
                show: false,
                showPdf: false
            });

            alert(data.rta);
        }
    }

    render() {

        if (this.context.sid == '') {
            return <Navigate
                to={{
                    pathname: '/',

                }} />
        }

        return (
            <div id="page-wrap">

                <div className="pagina">
                    <img className="logo-sm mb-4" src={logo} /> <br />
                    <h1 className="h5 mb-3 font-weight-normal">Bienvenido</h1>
                    <h2 className="h6 mb-3 font-weight-normal">Haciendo clic en el siguiente botón obtendra aleatoriamente los ganadores</h2><br />

                    <div className="container">
                        <div className="row">
                            <div className="col-sm">
                                <select className="form-control" id="premio" name="premio" value={this.state.premio} onChange={this.handleInputChange.bind(this)}>
                                    {this.state.premios.map((premio) => <option key={premio.id} value={premio.id}>{premio.title}</option>)}
                                </select>
                            </div>
                            <div className="col-sm">
                                <button type="button" className="btn btn-danger btn-block my-control" onClick={(e: any) => this.updateTable(e)}>
                                    OBTENER GANADORES
                            </button>
                            </div>
                            <div className="col-sm">
                                {
                                    this.state.showPdf ?
                                    (<button type="button" className="btn btn-primary btn-block my-control"  onClick={(e: any) => this.openPDF(e)}>
                                    DESCARGAR PDF
                                    </button> )
                                    :(<p>&nbsp;</p>)

                                }
                                
                            </div>
                        </div>
                    </div><br />



                    <Table striped bordered hover variant="dark" responsive className="tabla">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ID INTERNO</th>
                                <th>CLIENTE</th>
                                <th>CEDULA</th>
                                <th>BOLETA</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.boletas.map((item, key) => {
                                return <tr key={key}>
                                    <td>{key+1}</td>
                                    <td>{item.id}</td>
                                    <td>{item.cliente}</td>
                                    <td>{item.cedula}</td>
                                    <td>{item.boleta}</td>                                    
                                </tr>
                            })}
                        </tbody>
                    </Table>

                </div>
                <Modal show={this.state.show} dialogClassName="my-modal" size="sm" aria-labelledby="example-modal-sizes-title-sm" centered>

                    <Modal.Body >
                        Un momento por favor ....
                            <br />
                        <br />
                        <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            variant="danger"
                        />
                    </Modal.Body>

                </Modal>
            </div>

            
        );
    }
}


interface IConsumptionProps  {

}

interface IConsumptionState {

    boletas: IBoleta[];
    premios: IDDL[];
    premio: string;
    show: boolean;
    showPdf:boolean;
}

export default Rifa;