import * as React from 'react'
import Login from './pages/Login';
import Rifa from './pages/Rifa';
import Logout from './pages/Logout';
import { HashRouter, Routes, Route, Link } from 'react-router-dom';
import { TopMenu } from './components/TopMenu';
import {AppContextProvider, AppContextConsumer} from './AppContext';

export class App extends React.Component<IAppProps, IAppState> {

    constructor(props: IAppProps) {

        super(props);
    }

    render() {
        return (
            <AppContextProvider>
                <HashRouter>
                    <TopMenu pageWrapId='page-wrap' outerContainerId='app' />
                    <Routes>
                        <Route path="/rifas" element={<Rifa/>} />
                        <Route path="/" element={<Login />} />
                        <Route path="/logout" element={<Logout/>} />                        
                    </Routes>
                </HashRouter>
            </AppContextProvider>
        )
    }
}

interface IAppProps {
    title: string;
}

interface IAppState {

}