export interface INameValue {
  name: string;
  value: string;
}

export interface IDataArray {
  user_id: INameValue;
}

export interface ILogin {
  id: string;
  module_name: string;
  name_value_list: IDataArray;
  name: string;
  number: number;
  description: string;
}

export const http = <T>(request: RequestInfo): Promise<T> => {
  return new Promise(resolve => {
    fetch(request)
      .then(response => response.json())
      .then(body => {
        resolve(body);
      });
  });
};
