import * as React from 'react';
import { Navigate, Link } from 'react-router-dom';
import { http, ILogin } from '../structures/http';
import { IRta } from '../structures/IRta';
import { Spinner, Modal } from 'react-bootstrap';
//import * as crypto from 'crypto';
import * as crypto from 'crypto-js';
import { AppContext } from '../AppContext';
import { AppContextProvider } from '../AppContext';
import ReactDOM from 'react-dom';

const logo = require('../img/logo.svg').default;

class Login extends React.Component<ILoginProps, ILoginState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: ILoginProps) {

        super(props);

        this.state = {
            uname: '',
            pass: '',
            show: false
            
        }
    }

    handleLogin(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();

        this.setState({
            show: true
        });

        this.login();
    }

    async login() {

        const md5 = crypto.MD5(this.state.pass).toString();
        
        const rdata: string = "{\"user_auth\":{\"user_name\":\"" + this.state.uname + "\",\"password\":\"" + md5 + "\"},\"application_name\":\"SoapTest\"}";
        const url: string = this.context.url + "/service/v2/rest.php?method=login&input_type=JSON&response_type=JSON&rest_data=" + rdata;
        console.log(url);
        const data = await http<ILogin>(url);

        console.log(data);

        if (data.id) {

            console.log(data.id);

            this.context.setSession(data.id);

            this.setState({
                uname: this.state.uname,
                show: false                
            });
        }
        else {

            this.setState({
                show: false
            });

            alert(data.description);
        }
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {

        const { name, value } = e.target;

        this.setState({
            [name]: value
        } as any);
    }

    render() {

        if (this.context.sid != '') {

            return <Navigate
                to={{
                    pathname: '/rifas',
                }} />
        }

        return (

            <div id="page-wrap">

                <form onSubmit={e => this.handleLogin(e)} className="form-signin">
                    <img className="logo" src={logo} />
                    <br /><br />
                    <h1 className="h5 mb-3 font-weight-normal">Por favor ingrese</h1>
                    <div className="form-group">
                        <input
                            type="text"
                            name="uname"
                            onChange={e => this.handleInputChange(e)}
                            className="form-control my-control"
                            placeholder="Identificador"
                            value={this.state.uname}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            name="pass"
                            onChange={e => this.handleInputChange(e)}
                            className="form-control my-control"
                            placeholder="Password"
                            value={this.state.pass}

                        />
                    </div>

                    <button type="submit" className="btn btn-danger btn-block my-control">
                        INGRESAR
                    </button><br /><br />

                </form>


                <Modal show={this.state.show} dialogClassName="my-modal" size="sm" aria-labelledby="example-modal-sizes-title-sm" centered>

                    <Modal.Body >
                        Un momento por favor ....
                            <br />
                        <br />
                        <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            variant="danger"
                        />
                    </Modal.Body>


                </Modal>
            </div>

        )

    }
}

interface ILoginProps {

}

interface ILoginState {
    uname: string;
    pass: string;
    show: boolean;
    
}



export default Login;