import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../AppContext';


class Logout extends React.Component<ILogoutProps, ILogoutState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;


    constructor(props: ILogoutProps) {
        
        super(props);
        
    }

    render() {

        this.context.setSession('');
            
            return <Navigate
                to={{
                    pathname: '/',

                }} />

            return(<div></div>);    
    }
}



interface ILogoutProps  {
}
interface ILogoutState {}

export default Logout;